import Header from "../../components/header";
import Footer from "../../components/footer";
import styled from "styled-components";

const PageWrap = styled.div.attrs({
  className: "bg-[#100E17]",
})``;

const NavWrap = styled.div.attrs({
  className: "",
})`
  background-image: url("/imgs/bg1.png");
  background-size: 100% 100%;
`;

const NavTitle = styled.div.attrs({
  className:
    "text-center text-[#FF8F00] text-[16px] md:text-[44px] leading-normal",
})``;

const NavSubTitle = styled.div.attrs({
  className: "text-white text-[12px] md:text-[16px] text-center leading-normal",
})`
  margin-top: ${(props) => props.$mt && "40px"};
`;
const NavBtnWrap = styled.div.attrs({
  className:
    "mt-[25px] md:mt-[140px] flex justify-center pb-[20px] md:pb-[140px]",
})``;
const NavBtn = styled.div.attrs({
  className:
    "w-[187px] h-[76px] text-[20px] flex items-center justify-center cursor-pointer",
})`
  background-image: ${(p) =>
    p.$yellow ? "url(/imgs/btn-bg1.png)" : "url(/imgs/btn-bg2.png)"};
  background-size: 100% 100%;
  margin-right: ${(p) => p.$yellow && "60px"};
  color: ${(p) => (p.$yellow ? "#000" : "#FF8F00")};
`;
const NavContenet = styled.div.attrs({
  className: "px-[12px] md:px-0 mt-[24px] md:mt-[80px]",
})``;
const AboutWrap = styled.div.attrs({
  className:
    "bg-[length:300px_auto] md:bg-[length:720px_auto] max-w-full md:max-w-[1360px] mx-auto px-[12px] md:px-[100px] py-[25px] md:py-[200px]",
})`
  background-image: url("/imgs/block-bg1.png");
  background-repeat: no-repeat;
  background-position: bottom right;
`;
const AboutTitle = styled.div.attrs({
  className: "text-[16px] md:text-[56px] text-[#FF8F00] leading-normal",
})``;

const AboutTitleSpan = styled.span.attrs({
  className: "mr-[32px]",
})`
  color: ${(p) => p.$white && "#FFF"};
`;

const AboutSubTitle = styled.div.attrs({
  className: "text-[12px] md:text-[14px] leading-loose",
})`
  color: rgba(255, 255, 255, 0.6);
  margin-top: ${(p) => p.$mt && "16px"};
`;
const AboutBtnWrap = styled.div.attrs({
  className: "flex mt-[50px]",
})``;
const FtWrap = styled.div.attrs({
  className:
    "max-w-full md:max-w-[1360px] mx-auto px-[12px] md:px-[100px] pt-[20px] md:pt-[160px]",
})`
  background: url("/imgs/block-bg2.png") no-repeat;
  background-position: top right;
  background-size: 720px auto;
`;
const FtBtn = styled.div.attrs({
  className:
    "w-[198px] h-[68px] rounded-[8px] mr-[20px] flex items-center text-[16px] px-[12px] justify-center cursor-pointer",
})`
  border: ${(p) => (p.$block ? "2px solid #FF8F00" : "2px solid #fff500")};
  background: ${(p) => (p.$block ? "transparent" : "#ff8f00")};
  color: ${(p) => (p.$block ? "#FF8F00" : "#000")};
`;
const FtList = styled.div.attrs({
  className: "mt-[42px] block md:flex",
})``;
const FtItem = styled.div.attrs({
  className: "flex-1 mr-0 mb-[12px] md:mb-0 md:mr-[32px] p-[37px] pb-[64px]",
})`
  border: 1px solid #2b2b2b;
  &:last-child {
    margin-right: 0;
  }
`;
const FtItemImg = styled.img.attrs({
  className: "w-[96px]",
})``;
const FtItemTitle = styled.div.attrs({
  className: "text-[14px] mt-[60px] text-white",
})``;
const FtItemSubTitle = styled.div.attrs({
  className: "text-[#9B96B0] text-[12px] mt-[20px]",
})``;

const PtWrap = styled.div.attrs({
  className:
    "max-w-full md:max-w-[1360px] mx-auto px-[12px] md:px-[100px] py-[20px] md:py-[110px]",
})``;
const PtTitle = styled(AboutTitle).attrs({
  className: "text-center",
})``;

const PtTip = styled.div.attrs({
  className: "text-center text-white mt-[25px] text-[14px] md:text-[20px]",
})``;

const PtSubTitle = styled(AboutSubTitle).attrs({
  className: "text-center",
})``;
const PtImg = styled.img.attrs({
  className: "w-full block mt-[48px]",
})``;
const EmWrap = styled.div.attrs({
  className:
    "max-w-full md:max-w-[1360px] mx-auto px-[12px] md:px-[100px] py-[20px] md:py-[110px]",
})``;
const EmImgWrap = styled.div.attrs({
  className: "mt-[56px] block md:flex md:items-center justify-between",
})``;
const EmImg = styled.img.attrs({
  className: "w-[524px] mb-[46px] md:mb-0",
})``;
const EpWrap = styled.div.attrs({
  className:
    "max-w-full md:max-w-[1360px] mx-auto px-[12px] md:px-[100px] py-[20px] md:py-[110px]",
})``;

const EpBox = styled.div.attrs({
  className: "flex justify-between w-full flex-wrap mt-[20px] md:mt-[80px]",
})``;

const EpItem = styled.div.attrs({
  className:
    "w-[80px] md:w-[237px] h-[56px] md:h-[162px] shrink-0 flex-wrap mb-[20px] md:mb-[80px]",
})`
  background: url(/imgs/bg5.png) no-repeat;
  background-size: 100% 100%;
`;

export default function Home() {
  return (
    <PageWrap>
      <NavWrap>
        <Header></Header>
        <NavContenet>
          <NavTitle>Bitcoin Map Universal</NavTitle>
          <NavTitle>Game Engine Protocol</NavTitle>
          <NavSubTitle $mt>
            The universal game engine protocol based on Bitcoin Map
          </NavSubTitle>
          <NavSubTitle>
            is auniversal game engine protocol for Bitcoin full-chain
          </NavSubTitle>
          <NavSubTitle>games based on theBRC-420 protocol.</NavSubTitle>
          <NavBtnWrap>
            <NavBtn $yellow>Buy</NavBtn>
            <NavBtn>Builld</NavBtn>
          </NavBtnWrap>
        </NavContenet>
      </NavWrap>
      <AboutWrap>
        <AboutTitle>
          <AboutTitleSpan $white>About</AboutTitleSpan>
          <AboutTitleSpan>The</AboutTitleSpan>
        </AboutTitle>
        <AboutTitle>Protocol</AboutTitle>
        <AboutSubTitle $mt>Comprehensive support for game</AboutSubTitle>
        <AboutSubTitle>development on the Bitcoin chain:</AboutSubTitle>
        <AboutSubTitle>combined with mainstream Bitcoin inscription</AboutSubTitle>
        <AboutSubTitle>assets such as BRC-420 and BRC-20, it</AboutSubTitle>
        <AboutSubTitle>
          provides full-chain game development-related
        </AboutSubTitle>
        <AboutSubTitle>components to simplify game development</AboutSubTitle>
        <AboutSubTitle>difficulty for developers.</AboutSubTitle>
        {/* <AboutBtnWrap>
          <NavBtn $yellow>Buy</NavBtn>
          <NavBtn>Builld</NavBtn>
        </AboutBtnWrap> */}
      </AboutWrap>
      <FtWrap>
        <AboutTitle>
          <AboutTitleSpan $white>Key</AboutTitleSpan>
          <AboutTitleSpan>Features</AboutTitleSpan>
        </AboutTitle>
        <AboutSubTitle $mt>
          Webflow Enterprise gives your teams the power to
        </AboutSubTitle>
        <AboutSubTitle>
          build, ship, and manage sites collaboratively, at scale.
        </AboutSubTitle>
        <AboutBtnWrap>
          <FtBtn>Customize your game</FtBtn>
          <FtBtn $block>Learn more</FtBtn>
        </AboutBtnWrap>
        <FtList>
          <FtItem>
            <FtItemImg src="/imgs/icon2.png" />
            <FtItemTitle>Game rendering engine</FtItemTitle>
            <FtItemSubTitle>
              A high-performance game rendering engine that supports complex
              graphics and animations on the chain.
            </FtItemSubTitle>
          </FtItem>
          <FtItem>
            <FtItemImg src="/imgs/icon1.png" />
            <FtItemTitle>Scene and land generation tools</FtItemTitle>
            <FtItemSubTitle>
              Integrate Bitmap, providing tools to design and generate in-game
              environments and lands.
            </FtItemSubTitle>
          </FtItem>
          <FtItem>
            <FtItemImg src="/imgs/icon4.png" />
            <FtItemTitle>Asset issuance platform</FtItemTitle>
            <FtItemSubTitle>
              Allows creators and developers to publish and manage game assets.
            </FtItemSubTitle>
          </FtItem>
          <FtItem>
            <FtItemImg src="/imgs/icon3.png" />
            <FtItemTitle>Game incubation platform</FtItemTitle>
            <FtItemSubTitle>
              Provide resources and support to developers to facilitate the
              development and release of new games.
            </FtItemSubTitle>
          </FtItem>
        </FtList>
      </FtWrap>
      <PtWrap>
        <PtTitle>
          <AboutTitleSpan $white>Build On</AboutTitleSpan>
        </PtTitle>
        <PtTitle>Donation Protocol</PtTitle>
        <PtImg src="/imgs/bg2.png" />
      </PtWrap>
      <EmWrap>
        <PtTitle>
          <AboutTitleSpan>Economic</AboutTitleSpan>
          <AboutTitleSpan $white>Model</AboutTitleSpan>
        </PtTitle>
        <PtSubTitle>
          Bitcoin Map is an experimental BRC-20 MEME token with a unique nature.
          It aims to
        </PtSubTitle>
        <PtSubTitle>
          unite Bitcoin and Ordinals value consensus, enabling more individuals to
          comprehend and
        </PtSubTitle>
        <PtSubTitle>
          engage in the ecological development of Ordinals and return to the Bitcoin
          network
        </PtSubTitle>
        <EmImgWrap>
          <EmImg src="/imgs/bg3.png" />
          <EmImg src="/imgs/bg4.png" />
        </EmImgWrap>
      </EmWrap>
      <EpWrap>
        <PtTitle>
          <AboutTitleSpan $white>Ecological</AboutTitleSpan>
          <AboutTitleSpan>Partners</AboutTitleSpan>
        </PtTitle>
        <PtTip>Coming soon</PtTip>
        {/* <EpBox>
          <EpItem></EpItem>
          <EpItem></EpItem>
          <EpItem></EpItem>
          <EpItem></EpItem>
          <EpItem></EpItem>
          <EpItem></EpItem>
          <EpItem></EpItem>
          <EpItem></EpItem>
        </EpBox> */}
      </EpWrap>
      <Footer></Footer>
    </PageWrap>
  );
}
