import { useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import { useConnectModal, useAccountModal } from "@rainbow-me/rainbowkit";
import { useChainId } from "../../constants";
import { switchNetwork, getPublicClient } from "@wagmi/core";
import SnackTip from "../snack-tip";
import DehazeIcon from "@mui/icons-material/Dehaze";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";

const ListWrap = styled.div.attrs({
  className: "",
})`
  font-family: "press-start";
`;

const MobileMenuItem = styled.div.attrs({
  className: "text-white text-[14px] py-[12px]",
})`
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
`;

const CloseMenuIcon = styled(CloseIcon)`
  position: absolute;
  right: 12px;
  top: 12px;
  color: #ffffff;
  font-size: 32px !important;
`;

const MenuDrawerWrap = styled.div.attrs({
  className: "pt-[45px] px-[20px]",
})``;

const MenuDrawer = styled(Drawer).attrs({
  className: "",
})`
  & .MuiDrawer-paper {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

const Wrap = styled.div.attrs({
  className:
    "px-[12px] md:px-[100px] py-[24px] flex justify-between items-center text-white",
})``;

const MoreIcon = styled(DehazeIcon).attrs({
  className: "md:!hidden",
})`
  font-size: 20px;
`;

const Logo = styled.img.attrs({
  className: "w-[100px] md:w-[220px]",
})``;

const MenuWrap = styled.div.attrs({
  className: "hidden flex-1 md:flex justify-center",
})``;

const MenuItem = styled.div.attrs({
  className: "text-[12px] md:text-[16px] mr-[12px] md:mr-[57px] cursor-pointer",
})`
  &:last-child {
    margin-right: 0;
  }
`;

const RightWrap = styled.div.attrs({
  className: "hidden md:flex items-center",
})``;

const ContactImg = styled.img.attrs({
  className: "w-[24px] h-[24px] mr-[32px] cursor-pointer",
})``;

const ConnectBtn = styled.div.attrs({
  className:
    "hidden md:block h-[38px] px-[12px] leading-[38px] text-[#5D2E1C] text-[10px] cursor-pointer",
})`
  background-image: url("/imgs/bg6.png");
  background-size: 100% 100%;
`;

const MbileConnectBtn = styled.div.attrs({
  className:
    "mx-auto mt-[20px] w-[160px] h-[38px] leading-[38px] font-semibold text-center text-[#5D2E1C] text-[14px] cursor-pointer",
})`
  background-image: url("/imgs/bg6.png");
  background-size: 100% 100%;
`;

const DropWrap = styled.div.attrs({
  className: "hidden md:block mr-[24px] relative",
})`
  &:hover .block-bottom {
    display: block;
  }
`;

const DropItem = styled.div.attrs({
  className:
    "w-[130px] h-[38px] px-[12px] leading-[38px] text-[#5D2E1C] text-[10px] cursor-pointer flex items-center",
})`
  background-image: url("/imgs/bg6.png");
  background-size: 100% 100%;
`;

const DropItemBottomWrap = styled.div.attrs({
  className: "block-bottom absolute top-full left-0 pt-[8px]",
})`
  display: none;
  &:focus {
    display: none;
  }
`;

const BlockIcon = styled.img.attrs({
  className: "w-[18px] h-[18px]",
})``;

const BlockText = styled.div.attrs({
  className: "ml-[4px] leading-none",
})``;

export function formatAddress(address = "", firstlen = 6, lastlen = 3) {
  const tmp = [];
  if (!address) {
    return "";
  }
  tmp.push(address.substr(0, firstlen));
  tmp.push("...");
  if (lastlen !== false) {
    tmp.push(address.substr(-1 * lastlen));
  }
  return tmp.join("");
}

export default function Header() {
  const navigate = useNavigate();
  let location = useLocation();
  const [activeBlock, setActiveBlock] = useState(1);
  const { openConnectModal } = useConnectModal();
  const { address } = useAccount();
  const { chain } = getPublicClient();
  const { disconnect } = useDisconnect();
  const [btcAddress, setBtcAddress] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [tipConfig, setTipConfig] = useState({
    open: false,
    type: "success",
    title: "",
    message: "",
  });

  function onContact(url) {
    window.open(url);
  }

  function onClickBlock() {
    disconnect();
    const block = activeBlock === 1 ? 2 : 1;
    setActiveBlock(block);
  }

  function onCloseMenu() {
    setOpenMenu(false);
  }

  async function onConnector() {
    if (btcAddress) return;
    if (address) {
      if (useChainId !== chain.id) {
        await switchNetwork({
          chainId: useChainId,
        });
      }
      return;
    }

    if (activeBlock === 1) {
      openConnectModal && openConnectModal();
    } else {
      try {
        disconnect();
        if (window.unisat) {
          let accounts = await window?.unisat?.requestAccounts();
          accounts[0] && setBtcAddress(accounts[0]);
        } else {
          setTipConfig({
            open: true,
            type: "error",
            title: "Error",
            message: "Please install unisat Wallet",
          });
        }
      } catch (e) {
        console.log("connect failed");
      }
    }
  }

  function onClickMenu(url) {
    if (location.pathname == url) return;
    navigate(url);
  }

  function onCloseTip() {
    setTipConfig({ open: false, type: "success", title: "", message: "" });
  }

  function getAddress() {
    if (btcAddress) return formatAddress(btcAddress);
    if (address) {
      if (useChainId !== chain.id) {
        return "Wrong Network";
      } else {
        return formatAddress(address);
      }
    }

    return "CONNECT";
  }

  return (
    <Wrap>
      <Logo src="/imgs/logo.png" />
      <MenuWrap>
        <MenuItem onClick={() => onClickMenu("/")}>Home</MenuItem>
        {/* <MenuItem onClick={() => onClickMenu('/fairlaunch')}>IDO</MenuItem> */}
        <MenuItem onClick={() => onClickMenu("/")}>Build</MenuItem>
      </MenuWrap>
      <RightWrap>
        <ContactImg src="/imgs/contact3.png" />
        <ContactImg src="/imgs/contact1.png" />
        <ContactImg src="/imgs/contact2.png" />
        <ContactImg
          onClick={() =>
            onContact("https://bitengine-organization.gitbook.io/bitengine-1/")
          }
          src="/imgs/contact4.png"
        ></ContactImg>
      </RightWrap>
      <DropWrap>
        <DropItem>
          <BlockIcon src={`/imgs/logo${activeBlock}.png`} />
          <BlockText>{activeBlock === 1 ? "ETHEREUM" : "BITCOIN"}</BlockText>
        </DropItem>
        <DropItemBottomWrap>
          <DropItem onClick={onClickBlock}>
            <BlockIcon src={`/imgs/logo${activeBlock == 1 ? 2 : 1}.png`} />
            <BlockText>{activeBlock === 1 ? "BITCOIN" : "ETHEREUM"}</BlockText>
          </DropItem>
        </DropItemBottomWrap>
      </DropWrap>
      <ConnectBtn onClick={onConnector}>{getAddress()}</ConnectBtn>
      <MoreIcon
        onClick={() => {
          setOpenMenu(true);
        }}
      ></MoreIcon>
      <SnackTip
        message={tipConfig.message}
        title={tipConfig.title}
        open={tipConfig.open}
        type={tipConfig.type}
        onClose={onCloseTip}
      ></SnackTip>

      <MenuDrawer anchor="right" open={openMenu} onClose={onCloseMenu}>
        <MenuDrawerWrap>
          <CloseMenuIcon onClick={onCloseMenu}></CloseMenuIcon>
          <ListWrap>
            <MobileMenuItem onClick={() => onClickMenu("/")}>
              Home
            </MobileMenuItem>
            <MobileMenuItem onClick={() => onClickMenu("/")}>
              Build
            </MobileMenuItem>
          </ListWrap>
          <MbileConnectBtn onClick={onConnector}>
            {getAddress()}
          </MbileConnectBtn>
        </MenuDrawerWrap>
      </MenuDrawer>
    </Wrap>
  );
}
