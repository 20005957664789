import styled from "styled-components";
const FooterWrap = styled.div.attrs({
  className: "flex items-center px-[12px] md:px-[100px] py-[20px]",
})`
  background-color: rgba(255, 255, 255, 0.1);
`;
const Logo = styled.img.attrs({
  className: "w-[100px] md:w-[220px]",
})``;
const Text = styled.div.attrs({
  className:
    "hidden md:block w-[100px] md:w-[220px] flex-1 text-[16px] text-[#57606A] text-center",
})``;

export default function Footer() {
  return (
    <FooterWrap>
      <Logo src="/imgs/logo.png" />
      <Text>© 2024 Bitengine, Inc.</Text>
    </FooterWrap>
  );
}
