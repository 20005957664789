import styled from 'styled-components';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const TipAlert = styled(Alert)`
    & .MuiAlert-message {
        width: 300px;
    }
`

const TipTitle = styled(AlertTitle)`
    font-weight: bold;
`

export default function SnackTip({open, type, title,message, onClose}) {

    function transition(props) {
        return <Slide {...props} direction="down" />
    }

    return (
        open ?
        <Snackbar
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={6000}
            TransitionComponent={transition}
        >
            <TipAlert severity={type} elevation={6}>
                {
                    title && <TipTitle>{title}</TipTitle>
                }
                {message}
            </TipAlert>
        </Snackbar> : null
    )
}