import React, { useEffect, useState } from "react";
import { useAccount, useBalance } from "wagmi";
import { getPublicClient } from "@wagmi/core";
import styled from "styled-components";
import useCountdown from "../../../hooks/useCountDown";
import useGetIdoInfo from "../../../hooks/useGetIdoInfo";
import CustomConnectButton from "../custom-connect-button";
import usePurchase from "../../../hooks/usePurchase";
import { useChainId } from "../../../constants";

const ConfirmBtn = styled.div.attrs({
  className:
    "text-center text-white text-xl font-bold font-['Roboto'] leading-normal tracking-wide",
})`
  opacity: ${(p) => p.$disabled && "0.4"};
  cursor: ${(p) => (p.$disabled ? "not-allowed" : "pointer")};
`;

const Title = styled.div.attrs({
  className: "text-white text-2xl text-center py-[32px]",
})``;

const FairPage = ({ userInfo, balance = {} }) => {
  const { address, isConnected } = useAccount();
  const [inputAmount, setInputAmount] = useState("");
  const { chain } = getPublicClient();

  const [purchase] = usePurchase({
    value: inputAmount,
  });

  const idoInfo = useGetIdoInfo();
  let { data, isError, isLoading } = useBalance({
    address,
  });

  let leftTime = useCountdown({
    currentTime: "1702836131" * 1000,
    deadline: idoInfo?.endTime * 1000,
  });

  const onChange = (e) => {
    if (Number(e.target.value) < 0) {
      setInputAmount("0");
      return false;
    }
    if (Number(e.target.value) > 5) {
      setInputAmount("5");
      return false;
    }
    setInputAmount(e.target.value);
  };

  const onClickPurchase = () => {
    if (!address || useChainId !== chain?.id || inputAmount >= data?.formatted)
      return;
    purchase && purchase();
  };

  function getBtnText() {
    if (!address) return "UNCONNECTED";
    if (useChainId !== chain?.id) return "WRONG NETWORK";
    if (inputAmount >= data?.formatted) return "INSUFFICIENT BALANCE";
    return "CONFIRM PARTICIPATION";
  }

  return (
    <div
      className={`w-full flex flex-col items-center bg-[#171717] relative mx-auto pb-[50px]`}
    >
      <div className="box-border w-full px-[12px] md:px-0 md:w-[1250px] relative z-[2]">
        {/* 顶部 start */}
        {/* <div className="w-full flex items-center justify-center md:justify-end pt-[20px]">
                    <div className={`right-0`}>
                        <CustomConnectButton />
                    </div>
                </div> */}
        {/* 顶部 end */}

        <Title>Whitelist Stage</Title>

        {/* 内容 start */}
        <div className=" w-full block md:flex items-stretch relative mt-[20px] md:mt-[50px]">
          {/* 左边 */}
          <div className="tracking-tighter mr-0 md:mr-[100px] text-[12px] md:text-[14px] w-ful md:w-[624px] py-[12px] md:py-10 px-[12px] md:px-10 bg-gradient-to-b from-red-700 to-violet-700 rounded-[32px] border border-red-700 border-opacity-60">
            <div className="self-stretch justify-between items-center block md:flex">
              <div className="text-neutral-300 font-normal font-['Inter'] leading-normal">
                Allocation Per Wallet
              </div>
              <div className="text-zinc-100 font-medium font-['Roboto'] leading-normal">
                {idoInfo?.maxAllocation / 10 ** 18} ETH
              </div>
            </div>
            <div className="mt-[32px] self-stretch justify-between items-center block md:flex">
              <div className="text-neutral-300 font-normal font-['Inter'] leading-normal">
                Total Available Amount
              </div>
              <div className="text-zinc-100 font-medium font-['Roboto'] leading-normal">
                {/* 700,000,000 MAIX */}
                {idoInfo?.totalAmount
                  ? idoInfo?.totalAmount / 10 ** 18
                  : "-"}{" "}
                $BEGE
              </div>
            </div>
            <div className="mt-[32px] self-stretch justify-between items-center block md:flex">
              <div className="text-neutral-300 font-normal font-['Inter'] leading-normal">
                Price Per Unit
              </div>
              <div className="text-zinc-100 font-medium font-['Roboto'] leading-normal">
                {idoInfo?.tokenPrice ? "0.00000045" : "-"} ETH
              </div>
            </div>
            <div className="mt-[32px] self-stretch justify-between items-center block md:flex">
              <div className="text-neutral-300 font-normal font-['Inter'] leading-normal">
                Vesting
              </div>
              <div className="text-zinc-100 font-medium font-['Roboto'] leading-normal">
                No Lockup
              </div>
            </div>
            <div className="mt-[64px]">
              <div>
                <div className="text-orange-500 text-[14px] font-bold font-['Roboto'] leading-loose">
                  Progress
                </div>
                <div className="flex mt-1">
                  <div className="text-orange-500 font-bold font-['Roboto'] leading-normal">
                    {idoInfo?.totalTokensPurchased
                      ? idoInfo?.totalTokensPurchased / 10 ** 18
                      : "-"}{" "}
                    $BEGE
                  </div>
                  <div className="text-zinc-100 font-medium font-['Roboto'] leading-normal">
                    /{idoInfo?.totalAmount / 10 ** 18} $BEGE
                  </div>
                </div>
              </div>
              <div className="self-stretch mt-[32px]">
                <div className="w-full md:w-[544px] h-8 bg-red-900 rounded-lg relative">
                  <div
                    className="w-0 h-8 left-0 top-0 absolute bg-red-600 rounded-lg "
                    style={{
                      width:
                        (idoInfo?.totalTokensPurchased /
                          10 ** 18 /
                          (idoInfo?.totalAmount / 10 ** 18)) *
                          100 <
                        3
                          ? "3%"
                          : (idoInfo?.totalTokensPurchased /
                              10 ** 18 /
                              (idoInfo?.totalAmount / 10 ** 18)) *
                              100 <=
                            100
                          ? (idoInfo?.totalTokensPurchased /
                              10 ** 18 /
                              (idoInfo?.totalAmount / 10 ** 18)) *
                              100 +
                            "%"
                          : "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* 左边 */}

          {/* 右边 */}
          <div className="w-full md:w-[520px]">
            <div className=" mt-[20px] md:mt-0 w-full h-[120px] flex-col justify-start items-start gap-6 flex">
              <div className="text-zinc-100 text-[12px] md:text-lg font-medium font-['Roboto'] leading-7">
                Fair Launch will end in :
              </div>
              <div className="self-stretch justify-start items-center gap-[6px] md:gap-2 flex">
                <div className="grow shrink basis-0 px-[8px] md:px-3 py-2 bg-gradient-to-b from-red-700 to-violet-700 rounded-lg border border-red-700 border-opacity-60 flex-col justify-center items-center inline-flex">
                  <div className="text-center text-zinc-100 text-[14px] md:text-2xl font-medium font-['Roboto'] leading-normal tracking-wide">
                    {/* 2 */}
                    {leftTime?.day}
                  </div>
                  <div className="text-center text-zinc-100 text-opacity-75 text-[12px] md:text-sm font-normal font-['Roboto'] leading-tight">
                    DAYS
                  </div>
                </div>
                <div className="grow shrink basis-0 px-[8px] md:px-3 py-2 bg-gradient-to-b from-red-700 to-violet-700 rounded-lg border border-red-700 border-opacity-60 flex-col justify-center items-center inline-flex">
                  <div className="text-center text-zinc-100 text-[14px] md:text-2xl font-medium font-['Roboto'] leading-normal tracking-wide">
                    {/* 23 */}
                    {leftTime?.hour}
                  </div>
                  <div className="text-center text-zinc-100 text-opacity-75 text-[12px] md:text-sm font-normal font-['Roboto'] leading-tight">
                    HRS
                  </div>
                </div>
                <div className="grow shrink basis-0 px-[8px] md:px-3 py-2 bg-gradient-to-b from-red-700 to-violet-700 rounded-lg border border-red-700 border-opacity-60 flex-col justify-center items-center inline-flex">
                  <div className="text-center text-zinc-100 text-[14px] md:text-2xl font-medium font-['Roboto'] leading-normal tracking-wide">
                    {/* 59 */}
                    {leftTime?.minute}
                  </div>
                  <div className="text-center text-zinc-100 text-opacity-75 text-[12px] md:text-sm font-normal font-['Roboto'] leading-tight">
                    MINS
                  </div>
                </div>
                <div className="grow shrink basis-0 px-[8px] md:px-3 py-2 bg-gradient-to-b from-red-700 to-violet-700 rounded-lg border border-red-700 border-opacity-60 flex-col justify-center items-center inline-flex">
                  <div className="text-center text-zinc-100 text-[14px] md:text-2xl font-medium font-['Roboto'] leading-normal tracking-wide">
                    {/* 59 */}
                    {leftTime?.second}
                  </div>
                  <div className="text-center text-zinc-100 text-opacity-75 text-[12px] md:text-sm font-normal font-['Roboto'] leading-tight">
                    SECS
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-[520px] h-[0px] border border-rose-500 mt-[24px]"></div>

            <div className="w-full md:w-[520px] grow shrink basis-0 flex-col justify-between items-center flex mt-[24px]">
              <div className="self-stretch h-[230px] flex-col justify-start items-center gap-1 flex">
                <div className="self-stretch h-24 px-[12px] md:px-6 bg-black bg-opacity-30 rounded-lg border border-orange-600 border-opacity-50 justify-between items-center inline-flex">
                  <div className="justify-start items-center gap-3 flex">
                    <div className="text-zinc-100 text-[14px] md:text-xl font-medium font-['Roboto'] leading-7">
                      ETH
                    </div>
                  </div>
                  <div className="flex-col flex-1 min-w-0 justify-center items-end gap-1 inline-flex">
                    <div className="text-center w-full text-zinc-100 text-opacity-60 text-xl font-medium font-['Roboto'] leading-normal tracking-wide">
                      <input
                        value={inputAmount}
                        type="number"
                        onChange={onChange}
                        className={`outline-none w-full bg-transparent text-right border-none text-white`}
                        placeholder={`0.0`}
                      />
                    </div>
                    <div className="text-center text-zinc-100 text-opacity-75 text-[10px] md:text-[12px] font-normal font-['Roboto'] leading-tight">
                      Balance:{" "}
                      {data?.formatted
                        ? Math.floor(data.formatted * 10000) / 10000
                        : "-"}
                    </div>
                  </div>
                </div>
                <div className="mt-[20px] self-stretch h-24 px-[12px] md:px-6 bg-black bg-opacity-30 rounded-lg border border-orange-600 border-opacity-50 justify-between items-center inline-flex">
                  <div className="justify-start items-center gap-3 flex">
                    <div className="hidden md:block w-[30px] h-[30px] relative">
                      <div className="w-[30px] h-[30px] left-0 top-0 absolute bg-orange-600 rounded-full" />
                      <div className="w-[11px] h-[18px] left-[9.50px] top-[6px] absolute" />
                    </div>
                    <div className="text-orange-600 text-[14px] md:text-xl font-medium font-['Roboto'] leading-7">
                      $BEGE
                    </div>
                  </div>
                  <div className="flex-col justify-center items-end gap-1 inline-flex">
                    <div className="text-center text-zinc-100 text-opacity-60 text-xl font-medium font-['Roboto'] leading-normal tracking-wide">
                      {inputAmount && !isNaN(inputAmount / 0.00000045)
                        ? (inputAmount / 0.00000045)?.toFixed(3)
                        : "0.0"}
                    </div>

                    <div className="text-center text-zinc-100 text-opacity-75 text-[10px] md:text-[12px] font-normal font-['Roboto'] leading-tight">
                      Balance:{" "}
                      {idoInfo?.userAmount
                        ? idoInfo?.userAmount / 10 ** 18
                        : "-"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-[32px] self-stretch h-24 px-3 py-1.5 bg-orange-600 rounded-lg border border-orange-300 backdrop-blur-sm justify-center items-center gap-2 inline-flex">
                <ConfirmBtn
                  $disabled={
                    !address ||
                    useChainId !== chain?.id ||
                    inputAmount >= data?.formatted
                  }
                  onClick={onClickPurchase}
                >
                  {getBtnText()}
                </ConfirmBtn>
              </div>
            </div>

            <div className="!hidden w-[448px] h-[364px] p-10 bg-gradient-to-b from-rose-700 to-yellow-500 rounded-3xl flex-col justify-center items-start gap-8 inline-flex">
              <div className="self-stretch text-center text-white text-opacity-90 text-[32px] font-bold font-['Roboto'] leading-[48px]">
                PLEASE WAIT FOR FINAL TOKEN DISTRIBUTION
              </div>
            </div>
          </div>
          {/* 右边 */}
        </div>
        {/* 内容 end */}
      </div>
    </div>
  );
};

export default FairPage;
