import { createConfig, configureChains, WagmiConfig } from "wagmi";
import { mainnet, goerli } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import {
  getDefaultWallets,
  RainbowKitProvider,
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import {
  trustWallet,
  ledgerWallet,
  okxWallet,
} from "@rainbow-me/rainbowkit/wallets";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, goerli],
  [publicProvider()]
);

const projectId = "cd265090b163f9774bdd981f2a8992b6";
const { wallets } = getDefaultWallets({
  appName: "RainbowKit",
  projectId,
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: "Other",
    wallets: [
      trustWallet({ projectId, chains }),
      ledgerWallet({ projectId, chains }),
      okxWallet({ projectId, chains }),
    ],
  },
]);

const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

export default function Web3Providers({ children }) {
  return (
    <WagmiConfig config={config}>
      <RainbowKitProvider locale="en-US" chains={chains}>
        {children}
      </RainbowKitProvider>
    </WagmiConfig>
  );
}
