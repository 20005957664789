import { useState } from 'react'
import { useAccount, useContractRead } from 'wagmi';
import { idoContractConfig } from "../contract/ido";

const chainId = 5;

const useGetIdoInfo = () => {
  const { address } = useAccount()
  const [data, setData] = useState({});

  const {data: endTime, error: endTimeErr } = useContractRead({
    ...idoContractConfig,
    functionName: 'endTime',
    chainId,
    watch: true,
    args: []
  });

  const {data: maxAllocation, error: maxAllocationErr }  = useContractRead({
    ...idoContractConfig,
    functionName: "maxAllocation",
    chainId,
    watch: true,
    args: []
  });

  const {data: totalTokensPurchased, error: totalTokensPurchasedErr } = useContractRead({
    ...idoContractConfig,
    functionName: "totalTokensPurchased",
    chainId,
    watch: true,
    args: []
  });

  const {data: totalAmount, error: totalAmountErr } = useContractRead({
    ...idoContractConfig,
    functionName: "getTotalAmount",
    chainId,
    watch: true,
    args: []
  });

  const {data: tokenPrice, error: tokenPriceAmountErr }  = useContractRead({
    ...idoContractConfig,
    functionName: "getTokenPrice",
    chainId,
    watch: true,
    args: []
  });

  const {data: userAmount, error: userAmountErr } = useContractRead({
    ...idoContractConfig,
    functionName: "getUserAmount",
    chainId,
    watch: true,
    args: [address]
  });
  
  return {
    endTime: endTime?.toString(),
    maxAllocation: maxAllocation?.toString(),
    totalTokensPurchased: totalTokensPurchased?.toString(),
    totalAmount: totalAmount?.toString(),
    tokenPrice: tokenPrice?.toString(),
    userAmount: userAmount?.toString(),
  };
}

export default useGetIdoInfo