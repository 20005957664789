import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import FairLaunch from "./pages/fair-launch";
import Web3Providers from "./components/web3-providers";

function App() {
  return (
    <Web3Providers>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/fairlaunch" element={<FairLaunch />}></Route>
        </Routes>
      </BrowserRouter>
    </Web3Providers>
  );
}

export default App;
