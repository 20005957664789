import Header from "../../components/header";
import styled from "styled-components";
import FairlaunchContent from "./fair-launch-content";

const PageWrap = styled.div.attrs({
    className: "bg-[#100E17] min-h-screen",
})``;

export default function FairLaunch() {
    return (
        <PageWrap>
            <Header></Header>
            <FairlaunchContent></FairlaunchContent>
        </PageWrap>
    )
}