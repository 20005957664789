import { useState, useEffect } from "react";
import { parseEther } from "viem";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { idoContractConfig } from "../contract/ido";

const usePurchase = (props) => {
  const { address } = useAccount();

  const { config: contractWriteConfig } = usePrepareContractWrite({
    ...idoContractConfig,
    chainId: 5,
    functionName: "purchase",
    args: [],
    value: parseEther(props?.value || "0.01"),
  });
  console.log(contractWriteConfig, "contractWriteConfig");
  const {
    data: mintData,
    write: purchase,
    isLoading: isMintLoading,
    //isSuccess: isMintStarted,
    error: mintError,
  } = useContractWrite({
    ...contractWriteConfig,
    onError: (a, b) => {},
    onSuccess(a, b) {},
  });

  const {
    data: txData,
    isSuccess: txSuccess,
    error: txError,
  } = useWaitForTransaction(
    {
      hash: mintData?.hash,
      onSettled: () => {
        // setMintStarted(false);
      },
      onSuccess(a, b) {
        // props.onSuccess && props.onSuccess();
        // setMintStarted(true);
        // updateBack(address, upAddress);
        // dispatch({type: "update"})
      },
    },
    () => {}
  );
  console.log(`purchase: `, purchase);

  return [purchase];
};

export default usePurchase;
